<template>
    <div id="transaction">
        <div class="transaction-content">
            <div class="transaction_back" @click="back">
                <!-- <div><img src="../../assets/images/right-icon.png" /></div> -->
                <div><img src="../../assets/imgs/transaction/1.svg" /></div>
                <div>Back</div>
            </div>
            <div class="transaction_viewContent">
                <div class="transaction_viewContent-left">
                    <div class="historyLi">
                        <div class="history-img"><img :src="historyItem.awsurl?historyItem.awsurl:historyItem.ipfsurl"></div>
                        <div class="history-information1">
                            <div class="history-name">{{ historyItem.title }}</div>
                            <div class="history-price">
                                <div class="history-price-logo"><img :src="chainImg[chainId]"></div>
                                <div>{{ historyItem.price }}</div>
                            </div>
                        </div>
                        <div class="history-information2">
                            <div class="history-id">{{ historyItem.tokenid }}</div>
                            <div class="history-time">{{ historyItem.timeago }}</div>
                        </div>
                    </div>
                </div>
                <div class="transaction_viewContent-right">
                    <div class="right-view1" v-if="transactionState==='sell'">
                        <div class="right-view1-title">Price</div>
                        <div class="right-view1-input_price">
                            <div><input type="number" placeholder="0" onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))" v-model="price"></div>
                            <div class="priceCompany">
                                <template>{{tokenName[chainId]}}</template>
                            </div>
                            <div class="priceIcon"><img :src="chainImg[chainId]"></div>
                        </div>
                    </div>
                    <div class="right-view1" v-else>
                        <div class="right-view1-title">To</div>
                        <div class="right-view1-input"><input type="text" placeholder="Address" v-model="toaddress"></div>
                    </div>
                    <!--          <div class="right-view2">-->
                    <!--            <div class="right-view2-title">Fees</div>-->
                    <!--            <div class="right-view2-fee">-->
                    <!--              <div class="right-view2-feetitle">Service Fee</div>-->
                    <!--              <div class="right-view2-feeNumber">0.5%</div>-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <div class="right-complete buttonHover1" v-if="isTokenaAddress==true" :class="{'buttonProhibit': transactionState==='sell'? (price===''||price== '0'||price < 0): toaddress===''}" @click="setPricet">
                        <span v-if="operationLoading" class="loading_ani">Loading<span class="dotting"></span></span>
                        <span v-else>Confirm</span>
                    </div>
                    <div class="right-complete buttonHover1" v-if="isTokenaAddress==false" :class="{'buttonProhibit': transactionState==='sell'? (price===''||price== '0'||price < 0): toaddress===''}" @click="setPricet1">
                        <span v-if="operationLoading" class="loading_ani">Loading<span class="dotting"></span></span>
                        <span v-else>Confirm</span>
                    </div>
                </div>
            </div>
        </div>
        <interactiveProcess ref="interactiveProcess_ref" />
    </div>
</template>
<script>
import { queryHistoryMarket } from "../../web3/HistoryMarket";
import { createHistoryV1ERC721 } from "../../web3/HistoryV1ERC721.js";
import { createHistoryNewV1ERC721 } from "../../web3/HistorySeriesERC721";
import { createMinter721s } from "../../web3/HistorySeriesMinter01s";
const address = require('../../web3/web3Config.js');
const web3util = require('../../web3/common/web3util.js');
const wallet = require('../../web3/common/wallet.js');
import { ethers } from 'ethers'
import { chainName, chainImg, tokenName } from "../../web3/web3Config";
import { getChainIdNetwork } from "../../web3/common/wallet";

export default {
    name: "transaction",
    props: ['data'],
    inject: ['reload'],
    data() {
        return {
            chainImg: chainImg,
            tokenName: tokenName,
            baseUrlData: '',
            transactionState: 'sell',
            historyItem: {},
            admin: '',
            price: '',
            toaddress: '',
            itemData: {},
            operationLoading: false,
            chainName: chainName,
            HistoryERC721: '',
            HistorySeriesERC721: '',
            isTokenaAddress: '',
        }
    },
    computed: {
        mapState() {
            return this.$store.getters.walletInfo
        },
        chainId() {
            return this.$store.getters.walletInfo.chainId;
        }
    },
    watch: {
        '$store.getters.initStatusComplete': {
            immediate: true,
            handler(flag) {
                if (flag) {
                    this.init();
                }
            }
        }
    },
    components: {
        'interactiveProcess': () => import('../../components/interactiveProcess.vue'),
    },
    created() {

    },
    mounted() {




    },
    methods: {
        async init() {



            this.baseUrlData = localStorage.getItem('baseUrl')
            this.queryItemData = JSON.parse(this.$route.query.itemData)
            this.transactionState = this.$route.query.transactionState;
            this.historyItem = JSON.parse(this.$route.query.item);

            let res = '';
            if (this.mapState.chainId) {
                res = this.mapState.chainId
            } else {
                res = await getChainIdNetwork();
            }

            this.HistoryERC721 = address.address[this.chainName[res]].HistoryERC721;
            this.HistorySeriesERC721 = address.address[this.chainName[res]].HistorySeriesERC721;
            if (this.HistoryERC721 == this.$route.query.tokenaddress) {
                this.isTokenaAddress = true
            } else {
                this.isTokenaAddress = false
            }



        },
        back() {
            if (this.queryItemData.currentPage == 0) {
                this.$router.go(-1)
                this.reload()
            }
            if (this.queryItemData.currentPage == 1) {
                this.$router.go(-1)
                this.reload()
            }
            if (this.queryItemData.currentPage == 2) {
                this.$router.go(-1)
                this.reload()
            }
            if (this.queryItemData.currentPage == 3) {
                this.$router.go(-1)
                this.reload()
            }
            if (this.queryItemData.currentPage == 4) {
                this.$router.go(-1)
                // let isShow = false
                // let edit = true
                // this.$router.push({path:'/series',query:{id:this.historyItem.seriesid,isShow,edit}})
                this.reload()
            }
            // this.$route.query.backState == 'false' ? this.$router.go(-1) : this.$parent.myNFTsState = 1;
            // if(this.$route.query.backState == 'false'){
            // this.$router.push({path:'/myNFTs'})
            // this.reload()
            // }
        },
        setPricet() {
            let _this = this;
            let erc21 = createHistoryV1ERC721();
            if (this.transactionState === 'sell') {
                if (this.price !== '' && this.price != '0' && this.price > 0) {
                    this.operationLoading = true;
                    if (this.mapState.chainId) {
                        wallet.getWalletInfo().then((info) => {
                            this.admin = info.address;
                            web3util.getContractAdr("HistoryMarket_address").then((contractAdr) => {
                                erc21.isApprovedForAll(contractAdr).then(isApprove => {
                                    if (isApprove === false) {
                                        erc21.approveAll(contractAdr, true).then(() => {
                                            _this.sell();
                                        })
                                    } else {
                                        _this.sell();
                                    }
                                })
                            })
                        })
                    }
                }
            } else {
                if (this.toaddress !== '') {
                    this.operationLoading = true;
                    if (this.mapState.chainId) {
                        wallet.getWalletInfo().then((info) => {
                            erc21.safeTransferFrom(info.address, this.toaddress, _this.queryItemData.tokenid).then(() => {
                                let params = {
                                    admin: info.address.toLowerCase(),
                                    tokenid: _this.queryItemData.tokenid,
                                    toaddress: this.toaddress.toLowerCase()
                                };
                                this.$axios.post(this.baseUrlData + this.$api.post_transfertoken, params).then((res) => {
                                    this.operationLoading = false;
                                    this.$message({
                                        message: 'Operation succeeded',
                                        type: 'success'
                                    });
                                    setTimeout(() => {
                                        this.$router.go(-1)
                                        this.reload()
                                    }, 1500)
                                    // _this.$parent.queryGetseriesnfts();
                                    // _this.$parent.querySearchList();
                                    // _this.$parent.queryHistory();
                                })
                            }).catch(() => {
                                this.operationLoading = false;
                            })
                        })
                    }
                }
            }
        },
        setPricet1() {
            let _this = this;
            if (this.transactionState === 'sell') {
                if (_this.HistorySeriesERC721 == _this.historyItem.tokenaddress) {
                    let erc21 = createHistoryNewV1ERC721();
                    if (this.price !== '' && this.price != '0' && this.price > 0) {
                        this.operationLoading = true;
                        if (this.mapState.chainId) {
                            wallet.getWalletInfo().then((info) => {
                                this.admin = info.address;
                                web3util.getContractAdr("HistoryMarket_address").then((contractAdr) => {
                                    erc21.isApprovedForAll(contractAdr).then(isApprove => {
                                        console.log(isApprove, 'isApprove')
                                        if (isApprove === false) {
                                            erc21.approveAll(contractAdr, true).then(() => {
                                                _this.sell1();
                                            })
                                        } else {
                                            _this.sell1();
                                        }
                                    })
                                })
                            })
                        }
                    }
                } else if (_this.HistorySeriesERC721 !== _this.historyItem.tokenaddress) {
                    let erc21 = createMinter721s(_this.historyItem.tokenaddress);
                    if (this.price !== '' && this.price != '0' && this.price > 0) {
                        this.operationLoading = true;
                        if (this.mapState.chainId) {
                            wallet.getWalletInfo().then((info) => {
                                this.admin = info.address;
                                web3util.getContractAdr('HistoryMarket_address').then((contractAdr) => {
                                    erc21.isApprovedForAll(contractAdr).then(isApprove => {
                                        console.log(isApprove, 'isApprove')
                                        if (isApprove === false) {
                                            erc21.approveAll(contractAdr, true).then(() => {
                                                _this.sell1();
                                            })
                                        } else {
                                            _this.sell1();
                                        }
                                    })
                                })
                            })
                        }
                    }
                }
            } else {
                if (this.toaddress !== '') {
                    let erc21 = createHistoryNewV1ERC721();
                    this.operationLoading = true;
                    if (this.mapState.chainId) {
                        wallet.getWalletInfo().then((info) => {
                            erc21.safeTransferFrom(info.address, this.toaddress, _this.queryItemData.tokenid).then(() => {
                                let params = {
                                    admin: info.address.toLowerCase(),
                                    tokenid: _this.queryItemData.tokenid,
                                    toaddress: this.toaddress.toLowerCase(),
                                    network: info.network,
                                    tokenaddress: _this.historyItem.tokenaddress
                                };
                                this.$axios.post(this.baseUrlData + this.$api.post_transferseriestoken, params).then((res) => {
                                    this.operationLoading = false;
                                    this.$message({
                                        message: 'Operation succeeded',
                                        type: 'success'
                                    });
                                    setTimeout(() => {
                                        this.$router.go(-1)
                                        _this.reload()
                                    }, 1500)
                                    // _this.$parent.queryGetseriesnfts();
                                    // _this.$parent.querySearchList();
                                    // _this.$parent.queryHistory();
                                    // _this.$parent.myNFTsState = 1;
                                })
                            }).catch(() => {
                                this.operationLoading = false;
                            })
                        })
                    }
                }
            }
        },
        sell() {
            let _this = this;
            let ctr = queryHistoryMarket();
            this.$refs.interactiveProcess_ref.dialogTableVisible = true;
            this.$refs.interactiveProcess_ref.stepList = [{ name: 'Confirm', state: false }];
            this.$refs.interactiveProcess_ref.itemData = this.historyItem;
            this.$refs.interactiveProcess_ref.itemData.price = this.price;
            ctr.sellNft(_this.HistoryERC721, _this.queryItemData.tokenid, ethers.utils.parseUnits(String(this.price))).then(() => {
                this.$refs.interactiveProcess_ref.stepList = [{ name: 'Confirm', state: true }];
                let params = {
                    admin: this.admin.toLowerCase(),
                    tokenid: _this.queryItemData.tokenid,
                    price: this.price
                };
                this.$axios.post(this.baseUrlData + this.$api.post_setPricet, params).then((res) => {
                    this.operationLoading = false;
                    this.$message({
                        message: 'Operation succeeded',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.$router.go(-1)
                        this.reload()
                    }, 1500)
                    // _this.$parent.queryGetseriesnfts();
                    // _this.$parent.querySearchList();
                    // _this.$parent.queryHistory();
                    // _this.$parent.myNFTsState = 1;
                })
            }).catch(() => {
                this.$refs.interactiveProcess_ref.dialogTableVisible = false;
                this.operationLoading = false;
            })
        },
        sell1() {
            let _this = this;
            let ctr = queryHistoryMarket();
            if (this.mapState.chainId) {
                wallet.getWalletInfo().then((info) => {
                    this.$refs.interactiveProcess_ref.dialogTableVisible = true;
                    this.$refs.interactiveProcess_ref.stepList = [{ name: 'Confirm', state: false }];
                    this.$refs.interactiveProcess_ref.itemData = this.historyItem;
                    this.$refs.interactiveProcess_ref.itemData.price = this.price;
                    ctr.sellNft(_this.HistorySeriesERC721 == _this.historyItem.tokenaddress ? _this.HistorySeriesERC721 : _this.historyItem.tokenaddress, _this.queryItemData.tokenid, ethers.utils.parseUnits(String(_this.price))).then(() => {
                        this.$refs.interactiveProcess_ref.stepList = [{ name: 'Confirm', state: true }];
                        let params = {
                            admin: info.address.toLowerCase(),
                            tokenid: _this.queryItemData.tokenid,
                            price: _this.price,
                            network: info.network,
                            tokenaddress: _this.historyItem.tokenaddress
                        };
                        _this.$axios.post(_this.baseUrlData + _this.$api.post_setseriesprice, params).then((res) => {
                            _this.operationLoading = false;
                            _this.$message({
                                message: 'Operation succeeded',
                                type: 'success'
                            });
                            setTimeout(() => {
                                this.$router.go(-1)
                                _this.reload()
                            }, 1500)
                            // _this.$parent.queryGetseriesnfts();
                            // _this.$parent.querySearchList();
                            // _this.$parent.queryHistory();
                            // _this.$parent.myNFTsState = 1;
                        })
                    }).catch(() => {
                        this.$refs.interactiveProcess_ref.dialogTableVisible = false;
                        this.operationLoading = false;
                    })
                })
            }
        },
    }
}
</script>
<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

#transaction input[type="number"] {
    -moz-appearance: textfield;
}

#transaction {
    .transaction-content {
        max-width: 964px;
        margin: 120px auto;
    }

    .transaction_back {
        width: 150px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #707A83;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
            display: block;
            width: 20px;
            height: 20px;
        }

        div:nth-of-type(2) {
            margin-left: 28px;
        }
    }

    .transaction_viewContent {
        display: flex;

        .transaction_viewContent-left {
            margin: 22px 0 0 0;
            width: 400px;
            height: 480px;
            background: #FFFFFF;
            border: 1px solid #E5E8EB;
            border-radius: 10px;
            overflow: hidden;

            .history-img {
                height: 400px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    height: 100%;
                }
            }

            .history-information1 {
                display: flex;
                margin-top: 12px;
                font-family: Helvetica;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                color: #353840;
                padding: 0 12px;

                .history-name {
                    max-width: 200px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .history-price {
                    font-family: Helvetica;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    color: #000000;
                    margin-left: auto;
                    max-width: 100pxrem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    display: flex;
                    justify-content: space-between;

                    .history-price-logo {
                        margin-right: 6px;
                        margin-top: 1px;

                        img {
                            display: block;
                            width: 10px;
                            height: 14px;
                            object-fit: cover;
                        }
                    }
                }
            }

            .history-information2 {
                display: flex;
                font-family: Helvetica;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                padding: 9px 12px 24px 12px;
                color: #707A83;

                .history-id {
                    max-width: 160px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .history-time {
                    margin-left: auto;
                }
            }
        }

        .transaction_viewContent-right {
            margin-left: 32px;

            .right-view1 {
                margin-top: 20px;

                .right-view1-title {
                    font-family: Helvetica;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    color: #353840;
                    font-weight: bold;
                }

                .right-view1-input {
                    display: flex;
                    margin-top: 24px;

                    input {
                        width: 425px;
                        height: 50px;
                        background: #FFFFFF;
                        // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 0 1px #CCCCCC;
                        border: 1px solid #E5E8EB;
                        border-radius: 10px;
                        font-family: Helvetica;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        padding-left: 16px;
                    }

                    input::placeholder {
                        color: #CCCCCC;
                    }
                }

                .right-view1-input_price {
                    display: flex;
                    margin-top: 20px;
                    width: 420px;
                    height: 50px;
                    background: #FFE161;
                    border-radius: 10px;
                    padding: 0 16px 0 0;

                    div:nth-of-type(1) {
                        display: flex;

                        input {
                            width: 331px;
                            height: 50px;
                            background: #FFFFFF;
                            border: 1px solid #E5E8EB;
                            border-right: none;
                            border-radius: 10px;
                            font-family: Helvetica;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            padding-left: 16px;
                        }

                        input::placeholder {
                            color: #CCCCCC;
                        }
                    }

                    .priceCompany {
                        margin-left: auto;
                        font-family: Helvetica;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 50px;
                        color: #614F00;
                        margin-right: 10px;
                        font-weight: bold;
                    }

                    .priceIcon {
                        display: flex;
                        margin-top: 15px;

                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }

            .right-view2 {
                margin-top: 48px;

                .right-view2-title {
                    font-family: Helvetica;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 48px;
                    color: #000000;
                }

                .right-view2-fee {
                    display: flex;
                    margin-top: 26px;

                    .right-view2-feetitle {
                        font-family: Helvetica;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        color: #000000;
                    }

                    .right-view2-feeNumber {
                        font-family: Helvetica;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 19px;
                        color: #000000;
                        margin-left: auto;
                    }
                }
            }

            .right-complete {
                cursor: pointer;
                width: 425px;
                height: 50px;
                background: #FFE161;
                border-radius: 15px;
                font-family: Helvetica;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 50px;
                text-align: center;
                color: #614F00;
                margin: 48px auto 0 auto;
                font-family: Poppins-Bold, Poppins;
                font-weight: bold;
            }
        }
    }
}

// .buttonHover1:hover{
//   opacity: 0.8;
// }

.buttonProhibit {
    background: #EAECEF !important;
    // opacity: 0.2 !important;
    color: #B7BDC6 !important;
    cursor: no-drop;
    font-family: Poppins-Bold, Poppins !important;
    font-weight: bold !important;
}

// .buttonProhibit:hover{
//   background: #FFE161 !important;
//   opacity: 0.2 !important;
//   cursor: no-drop;
// }
</style>
<style>
/*Message prompt*/
.el-message {
    height: 48px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.12), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 4px 16px 4px rgba(0, 0, 0, 0.04);
}

.el-message__icon {
    font-size: 20px;
}

.el-message--success {
    border: none;
}

.el-message--warning {
    border: none;
}

.el-message--error {
    border: none;
}
</style>
<style lang="scss" scoped>
@media (max-width: 1125px) {
    #transaction {
        margin: 3.68641rem 0 0;
        padding: 1.3312rem .768rem;

        ::v-deep .transaction-content {
            margin: 0;

            .transaction_back {
                width: 100%;
                height: 1.3312rem;
                margin-bottom: .8192rem;

                div {
                    margin: 0;
                    /*font-family: 'Poppins';*/
                    font-style: normal;
                    font-weight: 400;
                    font-size: .8192rem;
                    line-height: 1.2288rem;
                    /* identical to box height, or 150% */
                    color: #707A83;

                    img {
                        width: 1.2288rem;
                        height: 1.2288rem;
                        margin-right: .6144rem;
                    }
                }
            }

            .transaction_viewContent {
                flex-direction: column;

                .transaction_viewContent-left {
                    margin: 0;
                    width: 100%;
                    height: 21.24805rem;
                    position: relative;
                    padding-top: 3.63521rem;
                    border: 0;
                    border-radius: 0;

                    .historyLi {
                        border: 1px solid #E5E8EB;
                        border-radius: .512rem .512rem 0px 0px;
                        overflow: hidden;
                        border-bottom: 0;


                        .history-img {
                            max-width: 17.61285rem;
                            height: 17.61285rem;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .history-information1 {
                            width: 100%;
                            position: absolute;
                            top: 0;

                            /*font-family: 'Poppins';*/
                            font-style: normal;
                            font-weight: 600;
                            font-size: .9216rem;
                            line-height: 1.3824rem;
                            /* identical to box height */
                            color: #353840;
                            margin: 0;
                            padding: 0;
                            justify-content: space-between;


                            .history-name {}

                            .history-price {
                                margin-left: 0;
                                /*font-family: 'Poppins';*/
                                font-style: normal;
                                font-weight: 600;
                                font-size: .9216rem;
                                line-height: 1.3824rem;
                                /* identical to box height */
                                color: #353840;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .history-price-logo {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin: 0 .512rem 0 0;

                                    img {
                                        height: 1.2288rem;
                                        width: auto;
                                    }
                                }
                            }

                        }

                        .history-information2 {
                            width: 100%;
                            position: absolute;
                            top: 1.8432rem;
                            padding: 0;
                            /*font-family: 'Poppins';*/
                            font-style: normal;
                            font-weight: 400;
                            font-size: .7168rem;
                            line-height: 1.1264rem;
                            /* identical to box height, or 161% */
                            color: #707A83;


                            .history-id {
                                max-width: 8.19202rem;
                            }

                            .history-time {}
                        }
                    }
                }

                .transaction_viewContent-right {

                    border: 1px solid #E5E8EB;
                    border-radius: 0 0 .512rem .512rem;
                    margin: 0;
                    padding: 0 .6144rem .8192rem;
                    border-top: 0;

                    .right-view1 {
                        margin: 0;
                    }

                    .right-view1-title {
                        height: 2.56001rem;
                        /*font-family: 'Poppins';*/
                        font-style: normal;
                        font-weight: 500;
                        font-size: .8192rem;
                        line-height: 2.56001rem;
                        /* identical to box height, or 150% */
                        color: #353840;

                    }

                    .right-view1-input_price {
                        width: 100%;
                        margin: 0;
                        background: transparent;
                        height: 2.04801rem;
                        padding: 0;
                        border-radius: 0;
                        align-items: center;
                        justify-content: space-between;

                        div:nth-of-type(1) {
                            max-width: 13.36323rem;
                            flex: 1;

                            input {
                                width: 100%;
                                height: 2.04801rem;
                                background: #f3f3f3;
                                border-radius: .4096rem;
                                border: 0;
                                padding: 0 .6144rem;
                                outline: none;
                                font-size: .7168rem;
                            }
                        }

                        .priceCompany {
                            /*font-family: 'Poppins';*/
                            font-style: normal;
                            font-weight: 400;
                            font-size: .7168rem;
                            /* identical to box height, or 171% */
                            color: #353840;
                            height: 2.04801rem;
                            line-height: 2.04801rem;
                            margin: 0;
                            order: 3;

                        }

                        .priceIcon {
                            margin: 0;
                            order: 2;

                            img {
                                width: 1.024rem;
                            }
                        }
                    }

                    .right-view1-input {
                        margin-top: 0;

                        input {
                            width: 100%;
                            height: 2.04801rem;
                            background: #f3f3f3;
                            border-radius: .3072rem;
                            border: 0;
                            padding: 0 .6144rem;
                            outline: none;
                            font-size: .7168rem;


                        }
                    }

                    .right-complete {
                        width: 16.43524rem;
                        height: 2.56001rem;
                        margin: .7168rem 0 0;
                        border-radius: .4096rem;

                        /*font-family: 'Poppins';*/
                        font-style: normal;
                        font-weight: 500;
                        font-size: .7168rem;
                        line-height: 2.56001rem;
                        /* identical to box height, or 186% */


                        color: #614F00;


                        &.buttonHover1 {}

                        &.buttonProhibit {}

                        .loading_ani {}
                    }

                }
            }

        }
    }






    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
}
</style>